import React, { useState, useEffect } from 'react'
import { styled, useTheme } from '@mui/material/styles'
import { useLocation } from 'react-router-dom'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import PhoneIcon from '@mui/icons-material/Phone'
import get from 'lodash.get'
import CircularProgress from '@mui/material/CircularProgress'

import Header from '../components/Header'
import { useGetSearchResultsQuery } from '../store/apis/Search'
import LoadingPage from './LoadingPage'
import TreatmentDialog from '../components/TreatmentDialog'
import FilterSection from '../components/FilterSection'
import { toTitleCase } from '../Utils'

const StyledBox = styled(Box)(props => ({
    background: props.theme.palette.background.default,
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
}))

const StyledContainer = styled(Container)(props => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    height: `calc(100% - ${props.headerheight}px)`,
    textAlign: 'center',
    [props.theme.breakpoints.down('md')]: {
        height: props.mobileHeight
    },
    [props.theme.breakpoints.down('md')]: {
        alignItems: 'center'
    },
}))


function ResultCard(props) {

    const NUMBER_OF_ROOM_RENTS_TO_SHOW_ON_SEE_LESS = 3

    const { name, address, phoneNo, roomAndPrices } = props

    const theme = useTheme()

    const isSmall = useMediaQuery(theme.breakpoints.down('md'))

    const [isCardOpen, setIsCardOpen] = useState(false)

    return (<Card sx={{
        padding: '1rem',
        mb: '0.5rem',
    }}>
        <Grid container columns={12}>
            <Grid sx={{
                textAlign: 'left',
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'column', md: 'row' },
                alignItems: {
                    xs: 'flex-start', sm: 'flex-start', md: 'center'
                }
            }} item xs={12}>
                <Typography
                    sx={{
                        mr: '0.5rem'
                    }}
                    variant="h6"
                    color='primary'>
                    {name}
                </Typography>
                {
                    !!phoneNo &&
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        <PhoneIcon sx={{
                            mr: '0.25rem',
                            ml: { xs: '0', md: '0.5rem' }
                        }}
                            fontSize='0.875rem'
                            color={theme.palette.text.secondary} />
                        <Typography variant="subtitle2" color={theme.palette.text.secondary}>
                            {phoneNo}
                        </Typography>
                    </Box>
                }
            </Grid>
            <Grid item xs={12} sm={12} md={8}>
                <Typography sx={{
                    textAlign: 'left',
                    width: {
                        xs: '100%', sm: '100%', md: '60%'
                    }
                }} variant="subtitle2" gutterBottom color={theme.palette.text.secondary}>
                    {address}
                </Typography>
            </Grid>
            <Grid sx={{
                textAlign: 'right'
            }} item xs={12} sm={12} md={4}>
                {Object.keys(roomAndPrices).map((item, index) => {
                    if (!isCardOpen && index > NUMBER_OF_ROOM_RENTS_TO_SHOW_ON_SEE_LESS - 1) {
                        return null
                    }
                    return <div key={index}>
                        <Typography sx={{
                            display: { xs: 'flex', md: 'block' },
                            flexDirection: 'row',
                            justifyContent: 'space-between'
                        }} variant="subtitle2" color={theme.palette.text.primary}>
                            {`${toTitleCase(item)}: `}
                            <Box
                                fontWeight='fontWeightMedium'
                                ml={isSmall ? 'auto' : '0'}
                                display='inline'>
                                {`₹ ${get(roomAndPrices, [item], '')}`}
                            </Box>
                        </Typography>
                    </div>
                })}
                {
                    !isCardOpen &&
                    Object.keys(roomAndPrices).length > NUMBER_OF_ROOM_RENTS_TO_SHOW_ON_SEE_LESS &&
                    <Typography
                        sx={{ cursor: 'pointer' }}
                        onClick={() => setIsCardOpen(true)}
                        variant="caption"
                        gutterBottom
                        color={theme.palette.primary.main}>
                        {`See More`}
                    </Typography>
                }
                {
                    isCardOpen &&
                    Object.keys(roomAndPrices).length > NUMBER_OF_ROOM_RENTS_TO_SHOW_ON_SEE_LESS &&
                    <Typography
                        sx={{ cursor: 'pointer' }}
                        onClick={() => setIsCardOpen(false)}
                        variant="caption"
                        gutterBottom
                        color={theme.palette.primary.main}>
                        {`See Less`}
                    </Typography>
                }
            </Grid>
        </Grid>
    </Card>)
}


const ResultPage = props => {

    const location = useLocation()

    const searchResults = useGetSearchResultsQuery(location.state)

    const [headerHeight, setHeaderHeight] = useState(82)
    const [showTreatmentDetailsCounter, setShowTreatmentDetailsCounter] = useState(0)
    const [treatmentDetails, setTreatmentDetails] = useState({})
    const [userFilters, setUserFilters] = useState({})
    const [cardsData, setCardsData] = useState([])
    const [isFiltering, setIsFiltering] = useState(true)


    useEffect(() => {
        if (get(searchResults, ['data', 'response', 'hospital_package_rates'], []).length === 0) {
            return
        }
        let tempCardsData = get(searchResults, ['data', 'response', 'hospital_package_rates'], [])
        tempCardsData = tempCardsData
            .filter((packageRate, index) => {
                let matchesFilterCondition = true
                if (Object.keys(userFilters).includes('Area')) {
                    if (get(userFilters, ['Area'], []).length === 0) return true
                    if (userFilters['Area'].includes(get(packageRate, ['hospital_details', 0, 'area']))) {
                        matchesFilterCondition = true
                    } else {
                        matchesFilterCondition = false
                    }
                }
                return matchesFilterCondition
            })
            .filter((packageRate, index) => {
                let matchesFilterCondition = false
                Object.values(get(packageRate, ['prices'], {})).forEach((item) => {
                    if (Object.keys(userFilters).includes('Price')) {
                        if (userFilters['Price'][0] <= parseFloat(item.replace(',', '')) && userFilters['Price'][1] >= parseFloat(item.replace(',', ''))) {
                            matchesFilterCondition = true
                        } else {
                            matchesFilterCondition = false
                        }
                    }
                })
                return matchesFilterCondition
            })
            .filter((packageRate, index) => {
                let matchesFilterCondition = true
                if (Object.keys(userFilters).includes('Room Type')) {
                    if (get(userFilters, ['Room Type'], []).length === 0) return true
                    const contains = get(userFilters, ['Room Type'], []).some(element => {
                        return Object.keys(get(packageRate, ['prices'], {})).includes(element)
                    })
                    matchesFilterCondition = contains
                }
                return matchesFilterCondition
            })
            .sort(function (packageRateA, packageRateB) {
                if (Object.keys(userFilters).includes('Sort')) {
                    if (get(userFilters, ['Sort']) === 'Price: Low to High') {
                        return get(packageRateA, ['min_price'], 1) - get(packageRateB, ['min_price'], 0)
                    } else if (get(userFilters, ['Sort']) === 'Price: High to Low') {
                        return get(packageRateB, ['max_price'], 1) - get(packageRateA, ['max_price'], 0)
                    }
                }
                return 1
            })
        setCardsData(tempCardsData)
        setIsFiltering(false)
    }, [userFilters])

    useEffect(() => {
        if (!searchResults.isSuccess) return
        setCardsData(get(searchResults, ['data', 'response', 'hospital_package_rates'], []))
        setTreatmentDetails(get(searchResults, ['data', 'response', 'treatment_details'], {}))
        setIsFiltering(false)
    }, [searchResults, searchResults.isSuccess])

    if (searchResults.isLoading) {
        return <LoadingPage />
    }

    return (
        <StyledBox>
            <Header setHeaderHeight={setHeaderHeight} />
            <StyledContainer headerheight={headerHeight}>
                <Grid sx={{ height: `100%` }} container columns={12} >
                    <Grid sx={{
                        borderRight: '1px solid gray',
                        display: { xs: 'none', sm: 'none', md: 'block' }
                    }}
                        item
                        component='div'
                        xs={0} sm={0} md={2.5}>
                        <FilterSection
                            filters={get(searchResults, ['data', 'response', 'filters'], {})}
                            setUserFilters={setUserFilters}
                            userFilters={userFilters}
                            setIsFiltering={setIsFiltering}
                        />
                    </Grid>
                    <Grid sx={{ height: `100%`, overflow: 'hidden' }} item xs={0} sm={0} md={9.5}>
                        <Box sx={{
                            display: 'flex',
                            padding: '1rem',
                            pb: '0.5rem',
                            alignItems: 'flex-end',
                            justifyContent: 'flex-start'
                        }}>
                            <Typography
                                sx={{
                                    mr: '0.5rem',
                                    lineHeight: '1',
                                    fontWeight: '500',
                                    textAlign: 'left'
                                }}
                                variant="h6"
                                component="div">
                                {get(location, ['state', 'treatment'], '')}
                            </Typography>
                            {
                                get(treatmentDetails, ['summary'], '').length > 0 &&
                                <Typography
                                    sx={{
                                        lineHeight: '1',
                                        ":hover": { cursor: 'pointer' }
                                    }} variant="caption"
                                    color='primary'
                                    component="a"
                                    onClick={() => setShowTreatmentDetailsCounter(prev => prev + 1)}>
                                    {'Read More'}
                                </Typography>}
                        </Box>
                        <Box sx={{
                            mt: '1rem',
                            mb: '0.5rem',
                            height: 'calc(100% - 3rem)',
                            overflow: 'auto'
                        }}>
                            <Box sx={{
                                display: 'flex',
                                padding: '1rem',
                                pt: 0,
                                flexDirection: 'column',
                            }}>
                                {
                                    cardsData.length > 0 && !isFiltering &&
                                    cardsData
                                        .map((packageRate, index) => {
                                            return <ResultCard
                                                key={index}
                                                name={get(packageRate, ['hospital_details', 0, 'name'])}
                                                address={get(packageRate, ['hospital_details', 0, 'address'])}
                                                phoneNo={get(packageRate, ['hospital_details', 0, 'contact_number'])}
                                                roomAndPrices={get(packageRate, ['prices'])}
                                            />
                                        })
                                }
                                {
                                    cardsData.length === 0 && !isFiltering &&
                                    <Typography
                                        sx={{
                                            mt: '30vh'
                                        }}
                                        variant='body1'>
                                        No Hospital Packages Found!
                                    </Typography>
                                }
                                {
                                    isFiltering &&
                                    <Box sx={{ mt: '30vh' }}>
                                        <CircularProgress />
                                    </Box>
                                }
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </StyledContainer>
            <TreatmentDialog treatmentDetails={treatmentDetails} openCounter={showTreatmentDetailsCounter} />
        </StyledBox >
    )
}

export default ResultPage