import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const searchApi = createApi({
    reducerPath: 'search',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BACKEND_URL
    }),
    endpoints: (builder) => ({
        getTreatments: builder.query({
            query: () => `/treatments`
        }),
        getAddressFields: builder.query({
            query: () => `/locations`
        }),
        getSearchResults: builder.query({
            query: (args) => ({
                url: `/search`,
                method: 'POST',
                body: args
            })
        })
    })
})

export const {
    useGetTreatmentsQuery,
    useGetAddressFieldsQuery,
    useGetSearchResultsQuery
} = searchApi