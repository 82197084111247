// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyA6qBU4LKFguculAAGId8MihsoVkAn-9jw",
    authDomain: "bookmytreatment1.firebaseapp.com",
    projectId: "bookmytreatment1",
    storageBucket: "bookmytreatment1.appspot.com",
    messagingSenderId: "461948175181",
    appId: "1:461948175181:web:0b6384a755b0e6cba62d04"
}

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig)
export const firebaseAuth = getAuth(firebaseApp)