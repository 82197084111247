import React from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'

const PageNotFound = () => {

    const theme = useTheme()
    const navigate = useNavigate()

    return (
        <Box sx={{
            background: theme.palette.background.default,
            display: 'flex',
            width: '100vw',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
        }}>
            <Typography sx={{ mb: '1rem' }} variant='body1'>Error 404: Page not found!</Typography>
            <Button onClick={() => navigate('/')}>Go to Home</Button>
        </Box>
    )
}

export default PageNotFound