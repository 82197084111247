import React, { useState, useRef } from 'react'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import Container from '@mui/material/Container'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import ButtonBase from '@mui/material/ButtonBase'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import { useDispatch, useSelector } from 'react-redux'
import get from 'lodash.get'
import { useNavigate } from 'react-router-dom'

import { firebaseAuth } from '../config/Firebase'
import { logout } from '../store/slices/AuthSlice'
import Logo from '../assets/logo_header.png'

const Header = props => {

  const theme = useTheme()
  const dispatch = useDispatch()
  const appbarRef = useRef()
  const navigate = useNavigate()

  const [anchorElUser, setAnchorElUser] = useState(null)

  const username = useSelector(state => state.auth.username)

  const settings = [
    {
      'name': 'Logout',
      'onClick': () => logoutHandler()
    }
  ]

  const isSmall = useMediaQuery(theme.breakpoints.down('md'))

  const logoutHandler = () => {
    firebaseAuth.signOut()
    dispatch(logout())
    navigate('/')
  }

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  return (
    <AppBar ref={appbarRef} color='secondary' elevation={0} position="static">
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          <Box sx={{ display: 'flex', flexGrow: 1 }}>
            <img src={Logo} style={{
              width: 'auto',
              height: isSmall ? '30px' : '50px',
              cursor: 'pointer',
              padding: '1rem 0'
            }}
              onClick={() => navigate('/')}
              alt='Book My Treatment' />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {!isSmall &&
              <Box sx={{ display: 'flex', flexDirection: 'column', mr: '5rem' }}>
                <Typography color='primary' variant="body2" >
                  For support, call 9050573561
                </Typography>
                <Typography color='primary' variant="body2" >
                  email to info@bookmytreatment.in
                </Typography>
              </Box>}
            <ButtonBase
              onClick={handleOpenUserMenu}
              disableRipple
              sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}>
                  <AccountCircleOutlinedIcon
                    sx={{ p: 0, mr: 1 }}
                    color={'primary'} />
                  <Typography
                    variant="subtitle1"
                    noWrap
                    color={theme.palette.primary.main}
                  >
                    {username}
                  </Typography>
                </Box>
              </Tooltip>
            </ButtonBase>
            <Menu
              elevation={1}
              disableAutoFocusItem
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={get(setting, ['name'])} onClick={get(setting, ['onClick'])}>
                  <Typography textAlign="center">{get(setting, ['name'])}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
export default Header
