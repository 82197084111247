import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isAuthChecked: false,
    isSignedIn: false,
    username: ''
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthChecked(state) {
            state.isAuthChecked = true
        },
        login(state, payload) {
            state.isSignedIn = true
            state.username = payload.payload
        },
        logout(state) {
            state.isSignedIn = false
        }
    }
})

export const { setAuthChecked, login, logout } = authSlice.actions
export default authSlice.reducer