import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'

const LoadingPage = () => {

    const theme = useTheme()

    return (
        <Box sx={{ background: theme.palette.background.default, display: 'flex', width: '100vw', height: { xs: '100%' }, justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
        </Box>
    )
}

export default LoadingPage