import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#2A4E79',
    },
    secondary: {
      main: '#E6B600',
    },
    background: {
      default: '#F2F7FF',
      paper: '#FAFFDD',
    },
    success: {
      main: '#8EF746',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        background: 'linear-gradient(45deg, #467ef7 30%, #4e6df7 90%)',
        border: 0,
        borderRadius: 3,
        boxShadow: '0 3px 5px 2px rgba(70, 126, 246, .3)',
        color: 'white',
        height: 48,
        padding: '0 30px',
      },
    },
  },
})