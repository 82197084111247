import React, { useState } from 'react'
import { styled, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import TextField from '@mui/material/TextField'
import PhoneIcon from '@mui/icons-material/Phone'
import GoogleIcon from '@mui/icons-material/Google'
import {
    signInWithRedirect,
    GoogleAuthProvider,
    RecaptchaVerifier,
    signInWithPhoneNumber
} from 'firebase/auth'

import LoginPageImage from '../assets/iStock-1307372703.jpg'
import Logo from '../assets/logo.png'
import { firebaseAuth } from '../config/Firebase'
import LoadingPage from './LoadingPage'

const StyledBox = styled(Box)(props => ({
    background: props.backgroundColor,
    height: '100%',
    minHeight: '-webkit-fill-available',
    [props.theme.breakpoints.down('md')]: {
        height: '100%'
    },
}))

const StyledContainer = styled(Container)(props => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    textAlign: 'center',
}))

const StyledImg = styled('img')(props => ({
    maxHeight: '100%',
    maxWidth: '100%',
    [props.theme.breakpoints.down('sm')]: {
        height: '100%'
    },
    objectFit: 'cover',
    objectPosition: '40% 50%',
    '@media (max-height: 480px)': {
        display: 'none'
    }
}))

const StyledButton = styled(Button)(props => ({
    marginRight: '1rem',
    fontSize: '0.7rem',
    [props.theme.breakpoints.down('md')]: {
        fontSize: '0.70rem',
        marginRight: '0',
        marginBottom: '0.5rem',
    },
    '&:last-child': {
        marginRight: '0',
    }
}))

const provider = new GoogleAuthProvider()

const LoginPage = () => {

    const theme = useTheme()

    const removeLoginImage = useMediaQuery('(max-height: 400px)')

    const [isLoggingIn, setIsLoggingIn] = useState(false)
    const [isLoggingInWithPhone, setIsLoggingInWithPhone] = useState(false)
    const [phoneNumber, setPhoneNumber] = useState('')
    const [phoneNumberError, setPhoneNumberError] = useState('')
    const [phoneVerificationResult, setPhoneVerificationResult] = useState()
    const [isOTPSent, setIsOTPSent] = useState(false)
    const [otp, setOtp] = useState('')
    const [otpError, setOtpError] = useState('')
    const [isVerifyingCaptcha, setIsVerifyingCaptcha] = useState(false)

    const signInWithGoogle = () => {
        setIsLoggingIn(true)
        signInWithRedirect(firebaseAuth, provider)
    }

    const signInWithPhone = () => {
        if (phoneNumber.length !== 10) {
            setPhoneNumberError('Phone Number should be 10 digit long')
            return
        }
        setIsVerifyingCaptcha(true)
        let verify = new RecaptchaVerifier('recaptcha-container', {}, firebaseAuth)
        signInWithPhoneNumber(firebaseAuth, '+91' + phoneNumber, verify)
            .then((response) => {
                setPhoneVerificationResult(response)
                setIsOTPSent(true)
            })
    }

    const changePhoneNumberHandler = () => {
        setIsVerifyingCaptcha(false)
        setPhoneNumber('')
        setIsLoggingInWithPhone(true)
        setIsOTPSent(false)
    }

    const ValidateOtp = () => {
        if (otp.length === 0) {
            setOtpError('Please Enter OTP')
            return
        }
        phoneVerificationResult.confirm(otp).then((result) => {
            // success
        }).catch((err) => {
            setOtpError('Incorrect OTP Entered')
        })
    }

    if (isLoggingIn) {
        return <LoadingPage />
    }

    return (
        <Grid sx={{ height: '100%', backgroundColor: theme.palette.background.paper }} container columns={12}>
            <Grid sx={{
                height: { xs: removeLoginImage ? '0' : '50%', md: '100%' }
            }}
                item
                xs={12} sm={6} lg={6}>
                <StyledImg src={LoginPageImage} height={window.innerHeight} />
            </Grid>
            <Grid sx={{
                height: { xs: '50%', md: '100%' }
            }} item xs={12} sm={6} lg={6}>
                <StyledBox
                    backgroundColor={theme.palette.background.paper}>
                    <StyledContainer maxWidth='sm'>
                        <img src={Logo} style={{
                            width: '100%',
                            height: 'auto'
                        }} alt={'Logo'} />
                        <Typography variant='subtitle2' sx={{
                            padding: '2px 0',
                            borderTop: '0.5px solid black',
                            borderBottom: '0.5px solid black',
                            textAlign: 'justify',
                            textJustify: 'inter-word',
                            mb: '1rem',
                            fontSize: { xs: '0.75rem', md: '14px' }
                        }}>
                            Freedom to search in your locality. Power to select the best. Confidence to take an informed decision. Welcome to India’s 1st platform that brings treatments, hospitals, room tariffs and more, all under one roof; so that everyone, across the country, gets the medical care they truly deserve! At BookMyTreatment.in, we work every moment to make this a reality.
                        </Typography>
                        {
                            !isLoggingInWithPhone && !isOTPSent &&
                            <Box sx={{
                                display: 'flex',
                                flexDirection: { xs: 'column', md: 'row' },
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}>
                                <StyledButton
                                    onClick={signInWithGoogle}
                                    startIcon={<GoogleIcon fontSize='small' />}
                                    variant="outlined">
                                    Login with Google
                                </StyledButton>
                                <StyledButton
                                    onClickCapture={() => setIsLoggingInWithPhone(true)}
                                    startIcon={<PhoneIcon />}
                                    variant="outlined">
                                    Login with Phone
                                </StyledButton>
                            </Box>
                        }
                        {
                            isLoggingInWithPhone && !isOTPSent &&
                            <>
                                {!isVerifyingCaptcha && <TextField
                                    sx={{
                                        width: { xs: '80%', md: '60%' },
                                        mb: !isVerifyingCaptcha ? '0rem' : '0.8rem'
                                    }}
                                    type='number'
                                    value={phoneNumber}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                    error={phoneNumberError.length > 0}
                                    helperText={phoneNumberError}
                                    size='small'
                                    label="Phone Number"
                                    variant="outlined" />}
                                <div style={{
                                    marginBottom: '1rem'
                                }} id="recaptcha-container"></div>
                                {!isVerifyingCaptcha && <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: { xs: '80%', md: '60%' }
                                }}>
                                    <StyledButton
                                        fullWidth
                                        onClick={signInWithPhone}
                                        variant="outlined">
                                        Send OTP
                                    </StyledButton>
                                    <StyledButton
                                        fullWidth
                                        onClick={() => setIsLoggingInWithPhone(false)}
                                        variant="outlined">
                                        Cancel
                                    </StyledButton>
                                </Box>}
                            </>
                        }
                        {
                            isOTPSent &&
                            <>
                                <TextField
                                    sx={{
                                        width: { xs: '80%', md: '60%' },
                                        mb: '0.8rem'
                                    }}
                                    type='number'
                                    value={otp}
                                    onChange={(e) => setOtp(e.target.value)}
                                    error={otpError.length > 0}
                                    helperText={otpError}
                                    size='small'
                                    label="Enter OTP"
                                    variant="outlined" />
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: { xs: '80%', md: '60%' }
                                }}>
                                    <StyledButton
                                        fullWidth
                                        onClick={ValidateOtp}
                                        variant="outlined">
                                        Verify
                                    </StyledButton>
                                    <StyledButton
                                        fullWidth
                                        onClick={changePhoneNumberHandler}
                                        variant="outlined">
                                        Change Number
                                    </StyledButton>
                                </Box>
                            </>
                        }
                    </StyledContainer>
                </StyledBox>
            </Grid>
        </Grid>
    )
}

export default LoginPage