import React from "react"
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom"
import { useSelector } from "react-redux"

import LoginPage from "./pages/LoginPage"
import SearchPage from "./pages/SearchPage"
import ResultPage from "./pages/ResultPage"
import PageNotFound from "./pages/PageNotFound"
import LoadingPage from "./pages/LoadingPage"

const Routing = (props) => {

    const isAuthChecked = useSelector(state => state.auth.isAuthChecked)
    const isSignedIn = useSelector(state => state.auth.isSignedIn)

    let routes

    if (!isAuthChecked) {
        routes = (
            <Routes>
                <Route path='/' element={<LoadingPage />} />
                <Route path='*' element={<LoadingPage />} />
            </Routes>
        )
    }

    if (!isSignedIn && isAuthChecked) {
        routes = (
            <Routes>
                <Route path='/' element={<LoginPage />} />
                <Route path='*' element={<PageNotFound />} />
            </Routes>
        )
    }

    if (isSignedIn) {
        routes = (
            <Routes>
                <Route path='/' element={<SearchPage />} />
                <Route path='/result' element={<ResultPage />} />
                <Route path='*' element={<PageNotFound />} />
            </Routes>
        )
    }

    return <Router>{routes}</Router>
}

export default Routing