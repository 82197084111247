import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'

import authReducer from './slices/AuthSlice'
import { searchApi } from './apis/Search'

const rootReducer = {
    auth: authReducer,
    [searchApi.reducerPath]: searchApi.reducer
}

const setMiddleware = (getDefaultMiddleware) => (
    getDefaultMiddleware().concat(searchApi.middleware)
)

export const store = configureStore({
    reducer: rootReducer,
    middleware: setMiddleware
})

setupListeners(store.dispatch)