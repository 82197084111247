import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import Slider from '@mui/material/Slider'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import get from 'lodash.get'
import set from 'lodash.set'
import cloneDeep from 'lodash.clonedeep'

import { toTitleCase } from '../Utils'

const Filter = props => {

    const { filter, userFilters, setUserFilters, setIsFiltering } = props


    const [sliderValue, setSliderValue] = useState([0, 100])
    const [checkboxShowMore, setCheckboxShowMore] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const [openSearchDialog, setOpenSearchDialog] = useState(false)
    const [dialogSearchText, setDialogSearchText] = useState('')
    const [checkboxOptions, setCheckboxOptions] = useState([])

    const checkboxHandler = (e, value) => {
        e.stopPropagation()
        setIsFiltering(true)
        const tempUserFilters = cloneDeep(userFilters)
        const prevFilterValue = get(tempUserFilters, [get(filter, ['display'], '')], [])
        const valueIndex = prevFilterValue.indexOf(value)
        if (valueIndex !== -1) {
            const tempPrevFilterValue = cloneDeep(prevFilterValue)
            tempPrevFilterValue.splice(valueIndex, 1)
            set(tempUserFilters, [get(filter, ['display'], '')], tempPrevFilterValue)
        } else {
            set(tempUserFilters, [get(filter, ['display'], ''), prevFilterValue.length], value)
        }
        setUserFilters(tempUserFilters)
    }

    const radioHandler = (value) => {
        setIsFiltering(true)
        const tempUserFilters = cloneDeep(userFilters)
        set(tempUserFilters, [get(filter, ['display'], '')], value)
        setUserFilters(tempUserFilters)
    }

    const handleOpenSearchDialog = () => {
        setOpenSearchDialog(true)
    }

    const handleCloseSearchDialog = () => {
        setOpenSearchDialog(false)
    }


    const handleSliderChange = (event, newValue) => {
        setSliderValue(newValue)
        setIsFiltering(true)
    }

    const getValueFromPercent = (percent) => {
        return parseInt((parseFloat(get(filter, ['max'], 100)) - parseFloat(get(filter, ['min'], 100))) * percent / 100) + parseInt(get(filter, ['min'], 100))
    }

    function sliderValuetext(value) {
        return `Rs. ${getValueFromPercent(value)}`
    }

    useEffect(() => {
        setCheckboxOptions(get(filter, ['options'], []))
        if (get(filter, ['options'], []).length > get(filter, ['max_options'], 100)) {
            setCheckboxShowMore(true)
        }
    }, [])

    useEffect(() => {
        if (get(filter, ['type'], '') !== 'slider') return
        const tempUserFilters = cloneDeep(userFilters)
        set(tempUserFilters, [get(filter, ['display'], '')], sliderValue.map(item => getValueFromPercent(item)))
        setUserFilters(tempUserFilters)
    }, [sliderValue])

    const checkboxComp = (allOptions = true) => (<FormControl component='fieldset' variant='standard'>
        <FormGroup row={allOptions}>
            {
                checkboxOptions
                    .filter((option, index) => {
                        if (allOptions) return true
                        if (searchValue.trim().length === 0) return true
                        else {
                            if (option.toLowerCase().startsWith(searchValue.toLowerCase())) {
                                return true
                            } else {
                                return false
                            }
                        }
                    })
                    .filter((option, index) => {
                        if (!allOptions) return true
                        if (dialogSearchText.trim().length === 0) return true
                        else {
                            if (option.toLowerCase().startsWith(dialogSearchText.toLowerCase())) {
                                return true
                            } else {
                                return false
                            }
                        }
                    })
                    .map((option, index) => {
                        if (index + 1 > get(filter, ['max_options'], 5) && !allOptions) return null
                        return (
                            <FormControlLabel
                                key={index}
                                sx={{
                                    width: !allOptions ? 'auto' : '14rem',
                                    textAlign: 'left'
                                }}
                                control={
                                    <Checkbox sx={{
                                        padding: '0',
                                        marginLeft: '9px',
                                        marginRight: '5px',
                                        marginBottom: '3px',
                                        marginTop: '0',
                                    }}
                                        size='small'
                                        name={option}
                                        checked={get(userFilters, [get(filter, ['display'], '')], []).includes(option)}
                                        onChange={(e) => checkboxHandler(e, option)}
                                    />
                                }
                                label={toTitleCase(option)}
                            />

                        )
                    })}
        </FormGroup>
    </FormControl>)

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            mr: '1rem',
            mt: '1rem',
        }}>
            <Typography variant='subtitle1' sx={{ mb: '0.25rem', fontWeight: '600' }}>{get(filter, ['display'], '')}</Typography>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
            }}>
                {
                    get(filter, ['has_search_input'], false) &&
                    <input style={{
                        borderRadius: '50px',
                        marginBottom: '1rem',
                        padding: '3px 10px',
                        border: '0.5px solid gray',
                        fontFamily: 'roboto',
                        fontSize: '0.75rem',
                        width: '100%'
                    }}
                        placeholder='Search'
                        value={searchValue}
                        onChange={e => setSearchValue(e.target.value)}
                    ></input>
                }
                {
                    get(filter, ['type'], '') === 'checkbox' &&
                    checkboxComp(false)
                }
                {
                    get(filter, ['type'], '') === 'checkbox' && checkboxShowMore &&
                    <Typography
                        onClick={handleOpenSearchDialog}
                        sx={{
                            textAlign: 'left',
                            cursor: 'pointer'
                        }}
                        variant='subtitle2'
                        color='primary'>
                        More
                    </Typography>
                }
                {get(filter, ['type'], '') === 'radio' &&
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                    >
                        {
                            get(filter, ['options'], []).map((option, index) => (
                                <FormControlLabel key={index} value={option} control={<Radio sx={{
                                    padding: '0',
                                    marginLeft: '9px',
                                    marginRight: '5px',
                                    marginBottom: '3px',
                                    marginTop: '0',
                                }}
                                    onChange={() => radioHandler(option)}
                                />} label={option} />
                            ))
                        }
                    </RadioGroup>}
                {
                    get(filter, ['type'], '') === 'slider' &&
                    <Slider
                        sx={{
                            width: '150px'
                        }}
                        value={sliderValue}
                        onChange={handleSliderChange}
                        valueLabelDisplay="auto"
                        disableSwap
                        valueLabelFormat={sliderValuetext}
                        marks={[
                            {
                                value: 0,
                                label: get(filter, ['min'], '')
                            },
                            {
                                value: 100,
                                label: get(filter, ['max'], '')
                            }
                        ]}
                    />
                }
            </Box>
            <Dialog
                keepMounted
                open={openSearchDialog}
                PaperProps={{
                    sx: {
                        maxWidth: '1000px',
                        width: '1000px',
                        height: '600px'
                    }
                }}
                onClose={handleCloseSearchDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {`${get(filter, ['display'], '')}`}
                </DialogTitle>
                <DialogContent>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexWrap: 'wrap'
                    }}>
                        <input style={{
                            borderRadius: '50px',
                            marginBottom: '1rem',
                            padding: '3px 10px',
                            border: '0.5px solid gray',
                            fontFamily: 'roboto',
                            fontSize: '0.75rem',
                        }}
                            placeholder='Search'
                            value={dialogSearchText}
                            onChange={e => setDialogSearchText(e.target.value)}
                        ></input>
                        {checkboxComp(true)}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseSearchDialog}>Cancel</Button>
                    <Button onClick={handleCloseSearchDialog} autoFocus>
                        Apply
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

export default Filter