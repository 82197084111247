import React, { useEffect } from "react"
import { useDispatch } from 'react-redux'

import Routing from "./Routing"
import { setAuthChecked, login } from './store/slices/AuthSlice'
import { firebaseAuth } from './config/Firebase'

const App = () => {

  const dispatch = useDispatch()

  useEffect(() => {
    firebaseAuth.onAuthStateChanged((user) => {
      if (user === null) {
        dispatch(setAuthChecked())
      } else {
        dispatch(setAuthChecked())
        dispatch(login(user.displayName))
      }
    })
  }, [])

  return (
    <Routing />
  )
}

export default App