import React, { useState } from 'react'
import { styled, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { alpha } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Autocomplete from '@mui/material/Autocomplete'
import SearchIcon from '@mui/icons-material/Search'
import get from 'lodash.get'

import LoadingPage from './LoadingPage'
import Header from '../components/Header'
import SearchPageImage from '../assets/SearchPageBGImage.jpg'
import { ListboxComponent, StyledPopper } from '../components/VirtualizedList'
import { useGetTreatmentsQuery, useGetAddressFieldsQuery } from '../store/apis/Search'

const StyledBox = styled(Box)(props => ({
    background: props.theme.palette.background.default,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
}))

const StyledButton = styled(Button)(props => ({
    width: '40%',
    [props.theme.breakpoints.down('md')]: {
        margin: '0 auto',
        width: '50%',
        marginTop: '1rem'
    },
    marginTop: '1rem',
    backgroundColor: 'white',
    color: '#733F29',
    '&:hover': {
        color: 'white',
        backgroundColor: '#733F29',
    },
}))

const SearchPage = props => {

    const theme = useTheme()
    const navigate = useNavigate()

    const treatments = useGetTreatmentsQuery()
    const addressFields = useGetAddressFieldsQuery()

    const [selectedTreatment, setSelectedTreatment] = useState({ 'procedure_name': '' })
    const [selectedAreaFields, setSelectedAreaFields] = useState({ 'field_value': '' })

    const isSmall = useMediaQuery(theme.breakpoints.down('md'))

    if (get(addressFields, ['isLoading']) || get(treatments, ['isLoading'])) {
        return <LoadingPage />
    }

    const search = () => {
        navigate('result',
            {
                state: {
                    treatment_id: get(selectedTreatment, ['id']),
                    treatment: get(selectedTreatment, ['procedure_name']),
                    location: get(selectedAreaFields, ['field_value']),
                    location_type: get(selectedAreaFields, ['field_type']),
                }
            }
        )
    }

    return (
        <StyledBox sx={{ height: '100%' }}>
            <Header />
            <Box sx={{
                backgroundImage: `url(${SearchPageImage})`,
                backgroundSize: 'cover',
                backgroundPosition: { xs: '28% 50%', md: '50% 50%' },
                height: `calc(100% - ${isSmall ? 30 : 50}px - 2rem)`
            }}>
                <Container sx={{
                    flexGrow: 1,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    height: `calc(100% - ${isSmall ? 30 : 50}px - 2rem)`,
                    alignItems: { xs: 'flex-start', md: 'center' },
                    mt: { xs: '1rem', md: '0' }
                }}
                    maxWidth='lg'>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        backgroundColor: alpha(theme.palette.secondary.main, 0.3),
                        padding: { xs: '1rem 2rem', md: '1rem 3rem' },
                        width: { xs: '100%', md: '45%' }
                    }}>
                        <Typography
                            variant={isSmall ? 'h6' : "h4"}
                            gutterBottom={!isSmall}
                            sx={{ color: 'white' }}
                            component="div">
                            {'Search | Choose | Get Treated!'}
                        </Typography>
                        <Autocomplete
                            sx={{
                                '& .MuiAutocomplete-clearIndicator': {
                                    color: 'white',
                                },
                                '& .MuiAutocomplete-popupIndicator': {
                                    color: 'white',
                                },
                            }}
                            value={selectedTreatment}
                            onChange={(event, newValue) => {
                                setSelectedTreatment(newValue)
                            }}
                            disablePortal
                            options={get(treatments, ['data', 'response'])}
                            getOptionLabel={(option) => get(option, ['procedure_name'])}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    InputLabelProps={{
                                        sx: {
                                            color: 'white',
                                        }
                                    }}
                                    InputProps={{
                                        ...params.InputProps,
                                        sx: {
                                            '& .MuiInput-input': {
                                                color: 'white'
                                            }
                                        }
                                    }}
                                    sx={{
                                        width: '100%',
                                        "& label.Mui-focused": {
                                            color: 'white'
                                        },
                                        '& .MuiInput-underline:after': {
                                            borderBottomColor: 'white',
                                        },
                                        '& .MuiInput-underline:before': {
                                            borderBottomColor: 'white',
                                        },
                                    }}
                                    label="Enter Treatment"
                                    variant="standard" />
                            )}
                        />
                        <Autocomplete
                            sx={{
                                '& .MuiAutocomplete-clearIndicator': {
                                    color: 'white',
                                },
                                '& .MuiAutocomplete-popupIndicator': {
                                    color: 'white',
                                }
                            }}
                            value={selectedAreaFields}
                            onChange={(event, newValue) => {
                                setSelectedAreaFields(newValue)
                            }}
                            disablePortal
                            options={get(addressFields, ['data', 'response'])}
                            getOptionLabel={(option) => get(option, ['field_value'])}
                            groupBy={(option) => get(option, ['field_type'])}
                            PopperComponent={StyledPopper}
                            ListboxComponent={ListboxComponent}
                            renderOption={(props, option) => [props, get(option, ['field_value'])]}
                            renderGroup={(params) => params}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    InputLabelProps={{
                                        sx: {
                                            color: 'white'
                                        }
                                    }}
                                    InputProps={{
                                        ...params.InputProps,
                                        sx: {
                                            '& .MuiInput-input': {
                                                color: 'white'
                                            },
                                        }
                                    }}
                                    sx={{
                                        width: '100%',
                                        marginBottom: '1rem',
                                        mt: '0.5rem',
                                        "& label.Mui-focused": {
                                            color: 'white'
                                        },
                                        '& .MuiInput-underline:after': {
                                            borderBottomColor: 'white'
                                        },
                                        '& .MuiInput-underline:before': {
                                            borderBottomColor: 'white'
                                        },
                                        // '&:hover': {
                                        //     '& .MuiInput-underline:before': {
                                        //         borderBottomColor: 'white'
                                        //     },
                                        // },
                                    }}
                                    label="Enter City | Area | Pincode | Hospital"
                                    variant="standard" />
                            )}
                        />
                        <StyledButton
                            startIcon={<SearchIcon />}
                            onClick={search}
                            variant="contained">
                            Search
                        </StyledButton>
                    </Box>
                </Container>
                <Typography
                    variant='body2'
                    sx={{
                        color: 'white',
                        textAlign: 'center',
                        position: 'absolute',
                        bottom: '0',
                        marginLeft: isSmall ? '0' : '0.8rem',
                        marginRight: isSmall ? '0' : '0.8rem',
                        marginBottom: '0',
                        padding: '0.5rem 0.8rem',
                        background: '#493b00c9',
                        fontSize: '0.85rem'
                    }}>
                    <span style={{ fontWeight: '600' }}>
                        {`Disclaimer: `}
                    </span>
                    {
                        !isSmall &&
                        <span>
                            GIPSA rates mentioned are indicative and may vary from confirmed rates. Please get in touch with us for any clarifications/support on 9050573561, email us on info@bookmytreatment.in or contact the hospital.
                        </span>
                    }
                    {
                        isSmall &&
                        <span>
                            GIPSA rates mentioned are indicative and may vary from confirmed rates. For support call us on 9050573561, email us on info@bookmytreatment.in or contact the hospital.
                        </span>
                    }
                </Typography>
            </Box>
        </StyledBox >
    )
}

export default SearchPage