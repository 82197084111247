import React, { useState, useEffect, useRef } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import get from 'lodash.get'

const TreatmentDialog = props => {

    const { openCounter, treatmentDetails } = props

    const [open, setOpen] = useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    useEffect(() => {
        if (openCounter === 0) {
            return
        }
        handleClickOpen()
    }, [openCounter])

    const descriptionElementRef = useRef(null)
    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef
            if (descriptionElement !== null) {
                descriptionElement.focus()
            }
        }
    }, [open])

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            scroll={'paper'}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogTitle id="scroll-dialog-title">
                {get(treatmentDetails, ['display_name'], '')}
                {
                    get(treatmentDetails, ['alternate_names'], []).length > 0 &&
                    <Typography variant='body1'>
                        {`Also known as: ${get(treatmentDetails, ['alternate_names'], []).join(', ')}`}
                    </Typography>
                }
            </DialogTitle>
            <DialogContent dividers={true}>
                <Typography variant='body1'>Summary</Typography>
                <DialogContentText
                    id="scroll-dialog-description"
                    ref={descriptionElementRef}
                    tabIndex={-1}
                >
                    {get(treatmentDetails, ['summary'], '')}
                </DialogContentText>
                {get(treatmentDetails, ['symptoms'], []).length > 0 &&
                    <>
                        <Typography variant='body1'>Symptoms</Typography>
                        {get(treatmentDetails, ['symptoms'], []).map((item, index) => {
                            return <DialogContentText
                                key={index}
                                ref={descriptionElementRef}
                                tabIndex={-1}
                            >
                                {`${index + 1}. ${item}`}
                            </DialogContentText>
                        })}
                    </>
                }
                {
                    get(treatmentDetails, ['causes'], []).length > 0 &&
                    <>
                        <Typography variant='body1'>Causes</Typography>
                        {get(treatmentDetails, ['causes'], []).map((item, index) => {
                            return <DialogContentText
                                key={index}
                                ref={descriptionElementRef}
                                tabIndex={-1}
                            >
                                {`${index + 1}. ${item}`}
                            </DialogContentText>
                        })}
                    </>
                }
                <Typography variant='body1'>Treatment</Typography>
                <DialogContentText
                    id="scroll-dialog-description"
                    ref={descriptionElementRef}
                    tabIndex={-1}
                >
                    {get(treatmentDetails, ['surgery'], '')}
                </DialogContentText>
            </DialogContent>
        </Dialog>
    )
}

export default TreatmentDialog