import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Filter from './Filter'

const FilterSection = props => {

    const { filters, setUserFilters, userFilters, setIsFiltering } = props

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            p: '1rem'
        }}>
            <Typography
                sx={{
                    fontWeight: '500'
                }}
                variant='body1'>
                Filters
            </Typography>
            {
                Object.values(filters).map((filter, index) => (
                    <Filter
                        setUserFilters={setUserFilters}
                        userFilters={userFilters}
                        key={index}
                        filter={filter}
                        setIsFiltering={setIsFiltering} />
                ))
            }
        </Box>
    )
}

export default FilterSection